import React from 'react'

function TermsBlack() {
  return (
    <div>
          <h1>Terms and Conditions</h1>
    <h2>1. Acceptance of Terms</h2>
    <p>
        You ("User" or "you") and us ("Company," "we," "us," or "our") are bound by these terms and conditions ("Agreement"), which control how you use our website and services. You agree to be bound by this Agreement by accessing or using the website, and you indicate that you have read and understand it.


    </p>
    <h2>2. User Responsibilities</h2>
    <p>
        By using the website and its services, you consent to abiding by all relevant laws and regulations. You assume full responsibility for any content you upload or send over the website, and while using our services, you are not allowed to act in an illegal, libellous, or abusive manner.

    </p>
    <h2>3. Intellectual Property</h2>
    <p>
        The Company is the owner of the website and all of its original features, content, and functionality. These are shielded against infringement by international copyright, trademarks, patents, trade secrets, and other intellectual property laws.

    </p>
    <h2>4. Third-Party Content</h2>
    <p>
        Links to external websites, services, or resources that are not within the Company's ownership or control may be found on the website. The content, privacy policies, and practices of any third-party websites or services are beyond our control and accountability. You understand and agree that the Company will not be held accountable or liable for any loss or damage resulting from or related to the use of such third-party content.

    </p>
    <h2>5. Limitation of Liability</h2>
    <p>
        The company, its directors, employees, partners, agents, suppliers, or affiliates shall not in any case be liable for any punitive, consequential, special, incidental, or indirect damages, including without limitation, loss of goodwill, use, or profits, or other intangible losses, resulting from (i) your access to, use of, or inability to use the

        to gain access to or make use of the website or its services, (ii) any behavior or material posted by third parties on the website, or (iii) any illegal access, usage, or modification of your communications or content.

    </p>
    <h2>6. Termination</h2>
    <p>
        For any reason, including without limitation if you violate this Agreement, we may immediately cancel or suspend your access to the website and services, without prior warning or liability. Your ability to use the website and its services will end instantly upon termination.


    </p>
    <h2>7. Governing Law</h2>
    <p>
        The laws of [Jurisdiction] shall govern this Agreement and be followed in its interpretation. Any lawsuit or other legal action resulting from or pertaining to this agreement will only fall under the jurisdiction of the courts in [Jurisdiction].


    </p>
    <h2>8. Modifications</h2>
    <p>
        We have the right to amend or change this Agreement at any moment, at our sole discretion. The Agreement will always be available on the website in its most recent edition. You agree to the updated Agreement if you keep using the website and services after any such changes.


    </p>
    <h2>9. Severability</h2>
    <p>
        The remaining terms of this agreement shall remain in full force and effect in the event that any provision is found to be invalid or unenforceable. An enforceable term will be substituted that, to the greatest extent feasible, reflects our meaning.


    </p>
    <h2>10. Entire Agreement</h2>
    <p>
        This Agreement replaces all previous and contemporaneous understandings and agreements and represents the whole agreement between you and the Company regarding the use of the website and services.

    </p>
    </div>
  )
}

export default TermsBlack
