import React from 'react'

function CookieBlack() {
  return (
    <main class="flex flex-col w-full max-w-[90%] container justify-center gap-2 ">
    <h1 class="font-bold text-4xl">Cookie Policy</h1>
    <h2 class="font-medium text-2xl">Introduction</h2>
    <p>
        This Cookie Policy explains how <b>
            <script>
                const hostname = window.location.hostname;
                const name = hostname[0].toUpperCase() + hostname.slice(1, hostname.length);
                document.write(name)</script>
        </b> ("we", "us", and "ours") uses cookies and similar
        technologies
        to
        recognize you when you visit our website at
        <script>document.write(window.location.origin)</script>
        . It explains what these technologies are and why
        we
        use
        them, as well as your rights to control our use of them.
    </p>


    <h2 class="font-medium text-2xl">What are cookies?</h2>
    <p>
        Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies
        are
        widely used by website owners in order to make their websites work, or to work more efficiently, as well as to
        provide
        reporting information.
    </p>

    <p>
        Cookies set by the website owner (in this case, <b>
            <script>document.write(name)</script>
        </b>) are called "first party cookies". Cookies
        set
        by
        parties other than the website owner are called "third party cookies". Third party cookies enable third party
        features
        or functionality to be provided on or through the website (e.g., advertising, interactive content, and
        analytics).
        The
        parties that set these third party cookies can recognize your computer both when it visits the website in
        question
        and
        also when it visits certain other websites.
    </p>

    <h2 class="font-medium text-2xl">Why do we use cookies?</h2>
    <p>
        We use first party and third party cookies for several reasons. Some cookies are required for technical reasons
        in
        order
        for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies
        also
        enable us to track and target the interests of our users to enhance the experience on our Online Properties.
        Third
        parties serve cookies through our Websites for advertising, analytics, and other purposes. This is described in
        more
        detail below.
    </p>


    <p>The types of cookies used on our Website:</p>
    <ul class="list-disc list-inside">
        <li>
            Essential Cookies: These cookies are strictly necessary to provide you with services available through our
            Websites
            and
            to use some of its features, such as access to secure areas.
        </li>
        <li>
            Performance and Functionality Cookies: These cookies are used to enhance the performance and functionality
            of our
            Websites but are non-essential to their use. However, without these cookies, certain functionality may
            become
            unavailable.
        </li>
        <li>
            Analytics and Customization Cookies: These cookies collect information that is used either in aggregate form
            to help
            us
            understand how our Websites are being used or how effective our marketing campaigns are, or to help us
            customize our
            Websites for you.
        </li>
        <li>
            Advertising Cookies: These cookies are used to make advertising messages more relevant to you. They perform
            functions
            like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for
            advertisers,
            and
            in some cases selecting advertisements that are based on your interests.
        </li>
    </ul>


    <h2 class="font-medium text-2xl">How can I control cookies?</h2>
    <p>
        You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting
        your
        preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of
        cookies
        you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with
        services.
    </p>
    <p>
        The specific steps to do this vary from browser to browser, so please refer to your browser's help menu for more
        information.
    </p>

    <h2 class="font-medium text-2xl">
        Changes to the Cookie Policy
    </h2>
    <p>
        We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we
        use
        or
        for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to
        stay
        informed about our use of cookies and related technologies.
    </p>

    <h2 class="font-medium text-2xl">Contact us</h2>
    <p>If you have any questions about our use of cookies or other technologies, please email us.</p>
</main>
  )
}

export default CookieBlack
