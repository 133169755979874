import React from 'react';
import './Playzoneau.css'; 
import finflg from '../images/finflg.png';
import back2 from '../images/back2.jpg';
import back3 from '../images/back3.jpg';
import back4 from '../images/back4.jpg';
import back5 from '../images/back5.jpg';
import background from '../images/back7.jpg';

function Finlandspinroo() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg sticky-top bg-white">
        <div className="container">
          <a className="navbar-brand" href="#"><img src={finflg} className="top-logo" alt="logo.com" /> <span>Matkatoimisto Suomessa</span></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link nohover" href="#home">Etusivu</a>
              </li>
              <li className="nav-item">
                <a className="nav-link nohover" href="#list">Luettelo</a>
              </li>
              <li className="nav-item">
                <a className="nav-link nohover" href="#contact-us">Ota yhteyttä</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section id="home" className="px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform" style={{background:`url(${background})`}}>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
              <h3 className="fw-bold mb-3 Playfair-font hero-title" style={{color:"white"}}>Vieraile Suomen parhaissa kasinohotelleissa</h3>
              <p>Haaveiletko lomasta, joka yhdistää olohuoneen ylellisyyden kasinon jännitykseen? Älä etsi kauempaa - Suomi tarjoaa valikoiman ensiluokkaisia kasinohotelleja, jotka takaavat unohtumattoman matkan. Olitpa kiinnostunut yksityisestä kattoterassista tai ylellisestä sviitistä henkeäsalpaavilla näkymillä, nämä huolella valitut kohteet tarjoavat täydellisen yhdistelmän ylellisyyttä, viihdettä ja mahdollisuuden kokeilla onneasi pelipöydissä.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="list" className="hotel section-padding padding-top-80">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-howtouse text-left pb-5">
                <div className="container-body">
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                    <div className="col mb-4">
                      <div className="card list-hover">
                        <img src={back2} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star"></span>
                          <h5 className="card-title Playfair-font"><a href="#" className="list-link Playfair-font">The Ville Resort & Casino</a></h5>
                          <p className="card-text">
                            Niille, jotka arvostavat sekä rauhallisuutta että pelin jännitystä, The Ville Resort & Casino tarjoaa täydellisen tasapainon. Rentoudu minimalistisissa mutta tyylikkäissä huoneissa ja valmistaudu ennen sukeltamista pelien maailmaan.
                          </p>
                          <a href="#" className="list-btn">Varaa nyt</a>
                        </div>
                        <div className="card-body">
                          <div className="booking-rating">
                            <div className="verified-point">8.6</div>
                            <div className="rating-info">
                              <h4>Erittäin hyvä</h4>
                              <p>100% vahvistetut arvostelut</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-4">
                      <div className="card list-hover">
                        <img src={back4} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star"></span>
                          <h5 className="card-title Playfair-font"><a href="#" className="list-link Playfair-font">Pullman Reef Hotel Casino</a></h5>
                          <p className="card-text">Koe puhdas eleganssi Pullman Reef Hotel Casinossa. Rentoudu kattouima-altaalla, ympärilläsi huojuvia palmuja ja upeat näkymät riutalle.</p>
                          <a href="#" className="list-btn">Varaa nyt</a>
                        </div>
                        <div className="card-body">
                          <div className="booking-rating">
                            <div className="verified-point">8.6</div>
                            <div className="rating-info">
                              <h4>Erittäin hyvä</h4>
                              <p>100% vahvistetut arvostelut</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-4">
                      <div className="card list-hover">
                        <img src={back3} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star"></span>
                          <h5 className="card-title Playfair-font"><a href="#" className="list-link Playfair-font">The Star Gold Coast</a></h5>
                          <p className="card-text">Etsitkö kuninkaallista pakopaikkaa? The Star Gold Coast on vastaus. Nauti ylellisistä sviiteistä, jotka määrittelevät uudelleen ylellisyyden.</p>
                          <a href="#" className="list-btn">Varaa nyt</a>
                        </div>
                        <div className="card-body">
                          <div className="booking-rating">
                            <div className="verified-point">8.6</div>
                            <div className="rating-info">
                              <h4>Erittäin hyvä</h4>
                              <p>100% vahvistetut arvostelut</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-4">
                      <div className="card list-hover">
                        <img src={back5} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star checked-star"></span>
                          <span className="fa fa-star"></span>
                          <h5 className="card-title Playfair-font"><a href="#" className="list-link Playfair-font">Crown Melbourne</a></h5>
                          <p className="card-text">Crown Melbourne tarjoaa upeita vaihtoehtoja, olitpa kiinnostunut kaupunkinäkymistä tai joen maisemista. Luksusta kauniissa villoissa, joissa on panoraamanäkymät.</p>
                          <a href="#" className="list-btn">Varaa nyt</a>
                        </div>
                        <div className="card-body">
                          <div className="booking-rating">
                            <div className="verified-point">8.6</div>
                            <div className="rating-info">
                              <h4>Erittäin hyvä</h4>
                              <p>100% vahvistetut arvostelut</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact-us" className="hotel section-padding bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe width="100%" height="560" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.991839210728!2d24.93941123826719!3d60.16127167001535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920bb63abb5d0f%3A0xf343c3d2f47d25a1!2sPursimiehenkatu%203%2C%2000150%20Helsinki%2C%20Finlandiya!5e1!3m2!1saz!2saz!4v1730367133087!5m2!1saz!2saz" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-us section-padding bg-white">
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-md-12">
                <div className="section-howtouse text-left">
                  <div className="container-body Playfair-font">
                    <h1 className="Playfair-font section-title">Muita mielenkiintoisia paikkoja</h1>
                  </div>
                  <div className="container-body">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-3">
                      <div className="col mb-4">
                        <div className="footer-links">
                          <ul>
                            <li><a href="#" className="footer-link-tag">The Ville Resort & Casino</a></li>
                            <li><a href="#" className="footer-link-tag">Pullman Reef Hotel Casino</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="footer-links">
                          <ul>
                            <li><a href="#" className="footer-link-tag">The Star Gold Coast</a></li>
                            <li><a href="#" className="footer-link-tag">Crown Melbourne</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col mb-4">
                        <div className="footer-links">
                          <ul>
                            <li style={{color:"#717171"}}>Yleiset kyselyt: info@Finlandspinroo.com</li>
                            <li style={{color:"#717171"}}>Matkakyselyt: travel@Finlandspinroo.com</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-light p-2 text-center">
        <div className="container padding-20">
          <div className="social-icon">
            <a href="#" className="fa fa-facebook"></a>
            <a href="#" className="fa fa-instagram"></a>
          </div>
          <a href='#' style={{color:"#717171", textDecoration:"none",margin:"10px 0",display:"block"}}>Tietosuojakäytäntö</a>
          <p className="text-dark footer-text">© 2023 Finlandspinroo.com Kaikki oikeudet pidätetään.</p>
        </div>
      </footer>
    </div>
  );
}

export default Finlandspinroo;
